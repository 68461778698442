/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

.title {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700; /* Adjust the font weight as needed */
    font-size: 24px; /* Adjust the font size as needed */
  }
  
  .text {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400; /* Adjust the font weight as needed */
    font-size: 16px; /* Adjust the font size as needed */
  }


  /* Example CSS styles */
.donut-chart-container {
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

/* You can customize the colors of the chart slices using CSS */
.donut-chart-slice:nth-child(1) {
  fill: #ff5733; /* Example color */
}

.donut-chart-slice:nth-child(2) {
  fill: #33ff57; /* Example color */
}

/* Add more styles as needed */
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  margin-top: 2px;
   padding-bottom: 10px; 
}

.pagination > li > a,
.pagination > li > span {
  --tw-bg-opacity: 1;
   background-color: #247ef4;
  --tw-bg-opacity: 0.1;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  --tw-text-opacity: 1;
  color: white;
  gap: 10px;

}

.pagination > li.active > a {
  --tw-bg-opacity: 1;
  background-color: #2fa3fb;

  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}


.pagination > li > a:hover {
  --tw-bg-opacity: 1;
  background-color: #2DA4FE;
  --tw-text-opacity: 1;
  color: #FFFFFF;
}


/* ===== Scrollbar CSS ===== */
* {
  scrollbar-width: auto;
  scrollbar-color: #8b8b8b #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  border-radius: 10px;
  border: 3px solid #ffffff;
}